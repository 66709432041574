<template>
  <div id="app">
    <!-- Navbar -->
    <nav class="navbar navbar-expand-lg bg-white navbar-absolute navbar-transparent" id="barra">
      <div class="container">
        <div class="navbar-translate">
          <a class="navbar-brand">
            <img src="assets/img/logo.png" width="70" alt="" />
          </a>
          <button
            class="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navigation"
            aria-controls="navigation-index"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-bar top-bar"></span>
            <span class="navbar-toggler-bar middle-bar"></span>
            <span class="navbar-toggler-bar bottom-bar"></span>
          </button>
        </div>
        <div
          class="collapse navbar-collapse"
          data-nav-image="assets/img//blurred-image-1.jpg"
          data-color="orange"
        >
          <ul class="navbar-nav ml-auto">
            <li class="nav-item">
              <a href="#home" class="nav-link">
                <p>Home</p>
              </a>
            </li>
            <li class="nav-item">
              <a href="#sobre" class="nav-link">
                <p>Sobre nós</p>
              </a>
            </li>
            <li class="nav-item">
              <a href="#servicos" class="nav-link">
                <p>Serviços</p>
              </a>
            </li>
            <li class="nav-item">
              <a href="#contato" class="nav-link" id="navbarDropdownMenuLink">
                <p>Contato</p>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
    <!-- End Navbar -->

    <main>
      <div class="wrapper">
        <section id="home">
          <div class="page-header page-header-small" style="min-height: 100vh !important">
            <div
              class="page-header-image"
              data-parallax="true"
              style="background-image: url('assets/img/bg.png')"
            ></div>
            <!-- <div class="content-center">
        <div class="container">
          <h1 class="title">SUA MERCADORIA REALIZADA PELO MELHOR CAMINHO</h1>
          <h2 class="title" style="text-shadow: 3px 5px 2px #474747;">Aqui na Pumalog você conta com qualidade no atendimento, preço justo, segurança e entrega no prazo.</h2>
        </div>
      </div> -->
          </div>
        </section>
        <div class="section section-about-us">
          <div class="container">
            <section>
              <div class="row">
                <div class="col-md-8 ml-auto mr-auto text-center">
                  <h2 class="title" id="sobre">SOBRE A PUMALOG</h2>
                  <h5 class="description" style="text-align: justify">
                    Localizada em Porto Feliz – SP Brasil, a PUMALOG surge da ideia de que o
                    transporte rodoviário, quando realizado com segurança, pontualidade e qualidade
                    no atendimento, é fundamental para o bom funcionamento da logística de empresas
                    dos mais variados segmentos.
                  </h5>
                </div>
              </div>
              <br />
            </section>

            <div class="separator separator-primary"></div>
            <div class="section-story-overview">
              <div class="row">
                <div class="col-md-6">
                  <!-- First image on the left side -->
                  <div
                    class="image-container image-left"
                    style="background-image: url('assets/img/img1.png')"
                  >
                    <p class="blockquote blockquote-primary">
                      "O trabalho dignifica o homem, o prazer aperfeiçoa a obra e a paixão da
                      sentido."
                      <!-- <br>
                  <br> -->
                      <!-- <small>-Walt Disney</small> -->
                    </p>
                  </div>
                  <!-- Second image on the left side of the article -->
                </div>
                <div class="col-md-5">
                  <!-- First image on the right side, above the article -->
                  <div
                    class="image-container image-right"
                    style="background-image: url('assets/img/img2.png')"
                  ></div>
                  <h2 id="servicos" style="font-weight: bold">Nossos serviços</h2>
                  <!-- <h4>Transporte 1</h4> -->
                  <ul>
                    <li>
                      <h5>
                        Transporte Rodoviário de Carga para Indústrias com veículos ¾ e carretas
                        (Baú ou Sider)
                      </h5>
                    </li>
                    <li>
                      <h5>Guarda-Volumes para empresas</h5>
                    </li>
                  </ul>
                  <br />
                </div>
                <section>
                  <div class="row">
                    <div class="col-md-8 ml-auto mr-auto text-center">
                      <h2 class="title" id="sobre">POR QUE ESCOLHER A PUMALOG ?</h2>
                      <h5
                        class="description"
                        style="text-align: justify; padding: 0px 15px !important"
                      >
                        Contamos com um time de profissionais que possuem um objetivo em comum:
                        proporcionar a melhor experiência do cliente da PUMALOG, com garantia de
                        entrega nos prazos estabelecido e rastreio de todos os veículos em trânsito.
                      </h5>
                    </div>
                  </div>
                  <div class="col-md-8 mx-auto my-5">
                    <div
                      class="image-container seguradora"
                      style="background-image: url('assets/img/seguradora.png')"
                    ></div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
        <section>
          <div class="section section-contact-us text-center">
            <div class="container">
              <h2 class="title" id="contato">FAÇA JÁ SEU ORÇAMENTO</h2>
              <div class="row">
                <div class="col-lg-6 text-center ml-auto mr-auto col-md-8 mb-4">
                  <img
                    src="assets/img/whatsapp.png"
                    style="cursor: pointer"
                    width="340"
                    height="140"
                    onclick="openWhats()"
                  />
                </div>
              </div>
              <p class="description">ou envie um email</p>
              <div class="row">
                <div class="col-lg-6 text-center ml-auto mr-auto col-md-8">
                  <div class="input-group input-lg">
                    <div class="input-group-prepend">
                      <span class="input-group-text"
                        ><i class="now-ui-icons users_circle-08"></i
                      ></span>
                    </div>
                    <input type="text" class="form-control" placeholder="Nome..." id="nome" />
                  </div>
                  <div class="input-group input-lg">
                    <div class="input-group-prepend">
                      <span class="input-group-text"
                        ><i class="now-ui-icons users_circle-08"></i
                      ></span>
                    </div>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Sobrenome..."
                      id="sobrenome"
                    />
                  </div>
                  <div class="input-group input-lg">
                    <div class="input-group-prepend">
                      <span class="input-group-text"><i class="now-ui-icons tech_mobile"></i></span>
                    </div>
                    <input
                      type="text"
                      class="form-control telefone"
                      placeholder="Telefone..."
                      id="telefone"
                    />
                  </div>
                  <div class="input-group input-lg">
                    <div class="input-group-prepend">
                      <span class="input-group-text"
                        ><i class="now-ui-icons ui-1_email-85"></i
                      ></span>
                    </div>
                    <input type="text" class="form-control" placeholder="Email..." id="email" />
                  </div>
                  <div class="textarea-container">
                    <textarea
                      class="form-control"
                      name="name"
                      rows="4"
                      cols="80"
                      placeholder="Digite sua mensagem..."
                      id="mensagem"
                    ></textarea>
                  </div>
                  <div class="send-button">
                    <button class="btn btn-primary btn-round btn-block btn-lg" onclick="send()">
                      Enviar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <footer class="footer footer-default">
          <div class="container" style="padding: 0px">
            <nav>
              <ul>
                <li>
                  <a href="#home">
                    <img src="assets/img/logo.png" width="50" alt="" />
                  </a>
                </li>
                <li>
                  <a href="#sobre"> Sobre nós </a>
                </li>
                <li>
                  <a href="#servicos"> Serviços </a>
                </li>
                <li>
                  <a href="#contato"> Contato </a>
                </li>
                <li>
                  <a href="#contato"> Contato </a>
                </li>
              </ul>
            </nav>
          </div>
        </footer>
      </div>
    </main>
  </div>
</template>

<script>
export default {
  name: "App",
  components: {},
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
